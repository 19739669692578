import React, { useState } from 'react'
import data from '../../assets/data/ProtfolioData'
import Modal from './Modal'

function Portfolio() {
    const [nextItem,setNext] = useState(6)
    const [portfolio,setPortfolio] = useState(data)
    const [ShowModal,setShowModal] =useState(false)
    const [ActiveId,SetActiveId] = useState(null)


    const loadMore = () => {
        setNext(prev => prev + 3)
    }

    const HandleShowModal=(id)=>{
        setShowModal(true)
        SetActiveId(id)

    }
  return (
    <section id='Portfolio'>
        <div className="container">
            <div className="text-center">
                <h2 className="text-headingColor  font-[800] text-[2.4rem] mb-5">
                    My projects
                </h2>
            </div>
            <div className="flex items-center justify-center gap-4 flex-wrap mt-14 ">
                {
                    portfolio.slice(0,nextItem).map((port,index) => (
                        <div key={index} data-aos="fade-zoom-in" data-aos-delay="50" data-aos-duration="1000"  className="group max-w-full sm:w-[48.5%] md:w-[31.8%] lg:w-[32%] relative z-[1] ">
                            <figure>
                                <img className='rounded-[8px]' src={port.imgUrl} alt="" />
                            </figure>
                            <div className="w-full h-full bg-primaryColor bg-opacity-40 
                            absolute top-0 left-0 z-[5] hidden group-hover:block ">
                                <div className="w-full h-full flex items-center justify-center">
                                    <button onClick={()=>HandleShowModal(port.id)} className='text-white bg-headingColor hover:bg-smalTextColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-150'>
                                        See Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="text-center mt-6">
                <button onClick={loadMore} className='text-white bg-headingColor 
                hover:bg-smalTextColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-200'>
                    Load More
                </button>
            </div>

        </div>
        {
            ShowModal && <Modal ActiveId={ActiveId} setShowModal={setShowModal}/>
        }
        

    </section>
  )
}
export default Portfolio
