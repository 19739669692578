import React from 'react'
import cv from '../../assets/images/cv.pdf'
import logoanass from '../../assets/images/logoanass.png'

export default function Footer() {
  const year = new Date().getFullYear()
  return (
    <footer className='bg-[#12141e] pt-12 sm:bg-bg-[#12141e]'>
      <div className="container bg-[#12141e]">
        <div className="sm:flex items-center justify-between md:gap-8">
        <div className="w-full sm:w-1/2 mb-12">
          <h2 className=" text-[26px] leading-10 text-white font-[600] mb-5">
          Let's bring your ideas to life
          </h2>
              <a href={cv} download="cv">
                <button  className='text-headingColor bg-white border border-solid border-white hover:text-white font-[700] flex items-center gap-2 hover:bg-smalTextColor ease-in duration-300 hover:border-smalTextColor  py-2 px-4 rounded-full'>
                  Download cv <span className='  hover:text-white font-[900] flex items-center  hover:bg-smalTextColor  ease-in duration-300'><i class="ri-arrow-down-line" ></i></span>
                </button>
              </a>
        </div>
        <div className="w-full sm:w-1/2 ">
          <p className="text-gray-300 leading-7 mt-4 sm:mt-0 ">
          Feel free to get in touch to discuss collaboration, opportunities, or any inquiries. Let's bring your ideas to life.                    
          </p>

          <div className="flex items-center gap-4 flex-wrap md:gap-8 mt-10">
            <span className='text-gray-300 font-[600] text-[15px] '>
              Follow Me:
            </span>
            <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
              <a href="#github" className='text-gray-300 font-[500] text-[18px] '>
              <i class="ri-instagram-fill"></i>
              </a>
            </span>
            <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
              <a href="#github" className='text-gray-300 font-[500] text-[18px] '>
              <i class="ri-facebook-fill"></i>
              </a>
            </span>
            <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
              <a href="#github" className='text-gray-300 font-[500] text-[18px] '>
              <i class="ri-linkedin-fill"></i>
              </a>
            </span>
            <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
              <a href="https://github.com/AnassElhaouat" className='text-gray-300 font-[500] text-[18px] '>
              <i class="ri-github-fill"></i>
              </a>
            </span>
        </div>
        
        </div>
        
      </div>
      <div>
          <ul className='flex items-center justify-center gap-10 mt-10'>
            <li><a className='text-gray-400 font-[700]' href="#About">About</a></li>
            <li><a className='text-gray-400 font-[700]' href="#Services">Services</a></li>
            <li><a className='text-gray-400 font-[700]' href="#Portfolio">Portfolio</a></li>
            <li><a className='text-gray-400 font-[700]' href="#Contact">Contact</a></li>
        </ul>
      </div>
      
      </div>
      <div className="bg-[#1b1e29] py-3 mt-14">
        <div className="container">
          <div className="flex items-center justify-center sm:justify-between">
            <div className="hidden sm:block">
              <div className="flex items-center gap-[10px]">
                <span className="w-[35px] h-[35px] flex items-center justify-center rounded-full bg-white ">
                <img src={logoanass} className='w-8 h-8' alt="" />

                </span>
              </div>
            </div>
            <div className="">
              <p className="text-gray-400 text-[14px]">
                Copyright {year} developed by AnassElHaouat -All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      
    </footer>
  )
}
