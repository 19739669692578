import React,{useRef,useEffect} from 'react';
import logoanass from '../../assets/images/logoanass.png'

const Header = () => {
    const headerRef = useRef(null)
    const menuRef = useRef(null)

    const stickyHeaderFunc = () =>{
        window.addEventListener('scroll',()=>{
            if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
                headerRef.current?.classList.add("sticky__header")
            }else{
                headerRef.current?.classList.remove("sticky__header")

            }
        })
    }

    useEffect(()=>{
        stickyHeaderFunc();
        return window.removeEventListener('scroll',stickyHeaderFunc);
    },[])
    const HandleClick = e =>{
         e.preventDefault();
         const targetAttr=e.target.getAttribute('href');
         const localisation = document.querySelector(targetAttr).offsetTop;
         window.scrollTo({
            top:localisation - 80 ,
            behavior: 'smooth',
            left:0
         })
    }
    const toggleMenu = () =>{
        menuRef.current?.classList.toggle('show__menu')
    }
    return (
        <header
        ref={headerRef} className='w-full h-[80px] leading-[80px] flex items-center'>
            <div className="container">
                <div className="flex items-center justify-between">
                    {/* ===========logo========= */}
                    <div className="flex items-center gap-[10px]">
                        {/* <span className="w-10 h-10 bg-primaryColor text-white text-[18px] font-[700] rounded-full items-center flex justify-center">
                            AE
                        </span> */}
                        <img src={logoanass} className='w-12 h-12' alt="" />
                        {/* <div className="leading-[20px]">
                            <h2 className="text-xl text-smalTextColor font-[500]">Anass El Haouat</h2>
                        </div> */}
                    </div>
                    {/* ===========end logo========= */}
                    {/* ===========start menu========= */}
                    <div className="menu" ref={menuRef} onClick={toggleMenu}>
                        <ul className='flex items-center gap-10'>
                            <li><a onClick={HandleClick} className='text-smalTextColor font-[700] text-[20px]' href="#About">About</a></li>
                            <li><a onClick={HandleClick} className='text-smalTextColor font-[700] text-[20px]' href="#Services">Services</a></li>
                            <li><a onClick={HandleClick} className='text-smalTextColor font-[700] text-[20px]' href="#Portfolio">Portfolio</a></li>
                            <li><a onClick={HandleClick} className='text-smalTextColor font-[700] text-[20px]' href="#Contact">Contact</a></li>
                        </ul>
                    </div>
                    {/* ===========end menu========= */}
                    {/* =========== menu right========= */}
                    <div className="flex items-center gap-4">
                        <a role='button' href='#Contact' className="flex items-center gap-2 px-4 py-4 max-h-6 rounded-[8px] text-smalTextColor hover:text-white font-600 border border-solid border-smalTextColor hover:bg-smalTextColor ease-in duration-500">
                            Let's Talk
                        </a>
                        <span onClick={toggleMenu} className="text-2xl text-smalTextColor md:hidden cursor-pointer">
                        <i class="ri-menu-line"></i>
                        </span>
                    </div>
                    {/* ===========end menu right========= */}
                </div>
            </div>
            
        </header>
    );
}

export default Header;
