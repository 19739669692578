import React from 'react'

function Services() {
  return (
    <section id="Services">
      <div className='container lg:pt-5'>
        <div className="text-center">
          <h2 className="text-headingColor font-[800] text-[2.4rem] mb-5">
            What Do i Help
          </h2>
          <p className="lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7">
          </p>
        </div>
        <div className="flex flex-col justify-center sm:py-12">
          <div className="w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
            <div className="relative text-gray-700 antialiased ext-sm font-semibold">
              <div className="hidden absolute w-1 sm:block bg-indigo-300 h-full left-1/2 transform -translate-x-1/2"></div>

              {/* left card  */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">

                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8 ">
                      <div data-aos='fade-right' data-aos-duration='1200'
                       className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 ">
                        <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl leading-7">
                        Frontend Development
                        </h3>
                        <p className="text-[15px] text-smalTextColor group-hover:text-white group-hover:font-[500]">
                        Focusing on creating the user interface of a website or application. This involves programming in HTML, CSS, and JavaScript to craft visually appealing and interactive web experiences.                        </p>

                       </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* right card*/}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">

                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8 ">
                      <div data-aos='fade-right' data-aos-duration='1200'
                       className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 ">
                        <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl leading-7">
                        Backend Development
                        </h3>
                        <p className="text-[15px] text-smalTextColor group-hover:text-white group-hover:font-[500]">
                        I have a strong foundation in database management. Whether it's designing databases, optimizing queries, or ensuring data security, I can manage your data effectively.                       
                        </p>

                       </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* left card  */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">

                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8 ">
                      <div data-aos='fade-right' data-aos-duration='1200'
                       className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 ">
                        <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl leading-7">
                        Full Stack Development
                        </h3>
                        <p className="text-[15px] text-smalTextColor group-hover:text-white group-hover:font-[500]">
                        As a Full Stack developer, I have experience in both front-end and back-end development, allowing me to build complete web solutions. From the user interface to the server, like React js and Laravel.                        </p>

                       </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    
  )
}

export default Services