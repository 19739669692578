import React from 'react'
import meme from '../../assets/images/meme.png'
import cv from '../../assets/images/cv.pdf'

function Hero() {
  return (
    <section className="pt-0 " id='About'>
        <div className="container pt-14">
            <div className="md:flex items-center justify-between sm:flex-col md:flex-row">
                {/* hero left content */}
                <div className="w-full md:basis-1/2">
                    <h5 data-aos="fade-right" data-aos-duration="1500" className="text-headingColor font-[600] text-[23px]">
                        Hello Welcome
                    </h5>
                    <h1 data-aos="fade-up" data-aos-duration="1500" 
                    className='text-headingColor font-[800] text-[1.8rem] leading-[35px] sm:leading-[46px] mt-5  sm:text-[1.9rem] md:text-[3.6rem]'>
                        I'm Anass El Haouat <br /> <span className='text-primaryColor'>Full Stack Developer</span>
                    </h1>

                    <div data-aos="fade-up"  data-aos-duration="1800" data-aos-delay="200" className="flex items-center gap-6 mt-7">
                        <a href={cv} download="cv">
                             <button  className='text-smalTextColor border border-solid border-smalTextColor hover:text-white font-[500] flex items-center gap-2 hover:bg-smalTextColor ease-in duration-300 py-2 px-4 rounded-full'>
                                Download cv <span className='  hover:text-white font-[700] flex items-center  hover:bg-smalTextColor  ease-in duration-100'><i class="ri-arrow-down-line" ></i></span>
                            </button>
                        </a>
                        <a href="#Portfolio" className='text-smalTextColor font-[500] border-b border-solid text-[16px] border-smalTextColor'>
                             <button >See Portfolio</button>
                        </a>
                        
                    </div>
                    <p data-aos="fade-left" data-aos-duration="1500" 
                         className="flex gap-2 text-headingColor mt-14 font-[600] text-[19px] leading-7 sm:pl-14 sm:pr-10">
                            <span><i class="ri-apps-2-line"></i></span>
                            Junior Full Stack Web Developer, skilled and passionate in both front-end and back-end development. Adaptable to new technologies, eager to learn, and ready to tackle challenges as part of a team. Strong foundation in databases and best development practices.                    </p>
                    <div className="flex items-center gap-9 mt-14">
                        <span className="text-smalTextColor text-[15px] font-[600]">
                            Follow me
                        </span>
                        <span>
                            <a href="https://www.instagram.com/anass.elhaouat1/" target='blank' className="text-smalTextColor text-[22px] font-[600]"><i class="ri-instagram-line"></i></a>
                        </span>
                        <span>
                            <a href="https://www.facebook.com/anass.elhouate.9/" target='blank'  className="text-smalTextColor text-[22px] font-[600]"><i class="ri-facebook-fill"></i></a>
                        </span>
                        <span>
                            <a href="https://www.linkedin.com/in/anass-el-haouat/" target='blank' className="text-smalTextColor text-[22px] font-[600]"><i class="ri-linkedin-box-fill"></i></a>
                        </span>
                        <span>
                            <a href="https://github.com/AnassElhaouat" target='blank' className="text-smalTextColor text-[22px] font-[600]"><i class="ri-github-fill"></i></a>
                        </span>

                    </div>
                </div>
                {/* end hero left content */}
                {/* left content img  */}
                <div className="basis-1/2 mt-14 sm:mt-0 ">
                    <figure className='flex items-center justify-center'>
                        <img src={meme} alt="kkkkkkkk" className='rounded-full bg-primaryColor'/>
                    </figure>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero