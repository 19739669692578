import React from 'react';

const Contac = () => {
    return (
        <section id='Contact' className='pb-16'>
            <div className="container">
                <h2 className="text-headingColor font-[700] text-[2.5rem] mb-8 text-center">Get in touch</h2>
                <div className="md:flex justify-between items-center">
                    <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
                    <iframe title='google-map' src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1264.2806720468125!2d-6.889800051651248!3d33.919027530559674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1shay%20el%20maghreb%20al%20arabi%20sect%2003%20bloc%2002%20temara!5e0!3m2!1sfr!2sma!4v1695816298583!5m2!1sfr!2sma"
                     className='border-0 w-full h-full' allowfullscreen="" loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center bg-indigo-100
                px-4 lg:px-8 py-8 ">
                    <form action="" className="w-full">
                        <div className="mb-5">
                            <input type="text" placeholder='Enter your name' className='w-full p-3 focus:outline-none rounded-[5px]' />
                        </div>
                        <div className="mb-5">
                            <input type="text" placeholder='Enter your email' className='w-full p-3 focus:outline-none rounded-[5px]' />
                        </div>
                        <div className="mb-5">
                            <input type="text" placeholder='Subject' className='w-full p-3 focus:outline-none rounded-[5px]' />
                        </div>
                        <div className="mb-5">
                            <textarea type="text" rows={3} placeholder='Write your message' className='w-full p-3 focus:outline-none rounded-[5px]' ></textarea>
                        </div>
                        <button className='w-full p-3 focus:outline-none rounded-[5px] text-white hover:bg-headingColor  bg-smalTextColor text-center ease-linear duration-150'>
                            Send message
                        </button>


                    </form>
                </div>
                </div>
                
            </div>
        </section>
    );
}

export default Contac;
