import React from 'react';
import portfolios from '../../assets/data/ProtfolioData';

const Modal = ({ActiveId ,setShowModal}) => {
    const Portfolio = portfolios.find((Portfolio) => Portfolio.id === ActiveId)
    return (
        <div className='w-full h-full fixed bg-headingColor bg-opacity-40 top-0 left-0 z-10 '>
            <div className=" w-11/12 md:max-w-[600px] md:w-full absolute top-1/2 left-1/2 bg-white z-20 
            rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5">
                <div className="">
                    <figure>
                        <img src={Portfolio.imgUrl} alt="" />
                    </figure>
                </div>
                <div className="">
                    <h2 className='text-2xl text-headingColor font-[700] my-3'>{Portfolio.title}</h2>
                    <p className="text-smalTextColor leading-7 text-[15px] ">{Portfolio.description}</p>
                    <div className="mt-5 flex items-center gap-3 flex-wrap">
                        <h4 className="text-headingColor text-[18px] text-[700]">
                            Technologies : 
                        </h4>
                        {
                            Portfolio.technologies.map((item,index)=>(
                                <span key={index} className="text-headingColor bg-gray-200 py-1 px-2 rounded-[5px] text-[14px]">
                                    {item}
                                </span>
                            ))
                        }
                    </div>
                    <a href={Portfolio.siteUrl}>
                        <button className="bg-primaryColor text-white py-2 px-4 my-8 rounded-[8px]  hover:bg-headingColor ease-in duration-300">Live Site</button>
                    </a>
                </div>
                <button onClick={()=>setShowModal(false)} className="w-[1.8rem] h-[1.8rem] absolute bg-white top-[1.7rem] right-[1.7rem] flex items-center justify-center
                rounded-[3px] text-[25px] leading-0">&times;</button>
            </div>
            
            
        </div>
    );
}

export default Modal;
