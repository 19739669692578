import './App.css';
import { useEffect } from 'react';
import Aos from 'aos';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Hero from './Components/Hero/Hero';
import Services from './Components/Hero/Services';
import Portfolio from './Components/Hero/Portfolio';
import Contac from './Components/Hero/Contac';

function App() {
  useEffect(()=>{
    Aos.init();
  },[]);

  return (
    <>
      <Header />
      <Hero />
      <Services />
      <Portfolio />
      <Contac />
      <Footer />
    </>
  );
}

export default App;
